import React from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
}

export const Main: React.FC<Props> = () => {
  return (
	<main className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 pb-20 pt-8">
		<Outlet />
	</main>
  );
};

