import React from 'react';

interface Props {
  updateWaiting: any,
  handleUpdate: any
}

export const UpdateWaiting = ({ updateWaiting, handleUpdate }: Props) => {
  if (!updateWaiting) return <></>;
  return (
	  <div className="rounded-md bg-red-200 p-4">
		  <div className="flex">
			  <div className="flex justify-center items-center">
				  <svg className="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
					  <path fillRule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clipRule="evenodd"/>
				  </svg>
			  </div>
			  <div className="ml-3 flex items-center justify-center gap-6">
				  <h3 className="text-sm font-medium text-red-800">Neues Update verfügbar!</h3>
				  <div className="text-sm text-red-700">
					  <button onClick={handleUpdate}
							  className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md text-red-50 bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							  <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
						  </svg>
						  <span>Update</span>
					  </button>
				  </div>
			  </div>
		  </div>
	  </div>
  );
};

