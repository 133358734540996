import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Props {

}

interface Statistics {
  todaySum: number;
  todayTodo: number;
  todayReady: number;
  hours: number;
}

export const UserStatistics = (props: Props) => {

  const navigate = useNavigate();

  const [statistics, setStatistics] = useState<Statistics>();

  useEffect(() => {
    console.log('fetch user statistics');

    axios.get('/api/user/statistics').then((response) => {

      console.log('response', response, response.data);

      const data = response.data;
      setStatistics(data);

    }).catch((error) => {
      console.log(error	);
    });
  }, []);

  return (
	<div className="rounded-md bg-gray-100 p-4 flex justify-center">
		<div className="flex">
			<div
                onClick={() => navigate('/tickets/open')}
                style={{  width: 100, height: 100, aspectRatio: '1/1', justifyContent: 'center', display: 'flex', flexDirection: 'column', borderRadius: 100, borderWidth:4, borderColor: 'lightgrey' }}>
				<p style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', color: 'red' }}>{statistics?.todayTodo ?? '--'}</p>
				<p style={{ fontSize: 10, textAlign: 'center', color: 'gray' }}>Offene Tickets</p>
			</div>
			<div style={{  width: 140, height: 140, aspectRatio: '1/1', justifyContent: 'center', display: 'flex', flexDirection: 'column', borderRadius: 100,  borderWidth:4, borderColor: 'lightgrey', marginTop: 25 }}>
				<p style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bold', color: 'black'  }}>{statistics?.hours ?? '--'}</p>
				<p style={{ fontSize: 12, textAlign: 'center', color: 'gray' }}>Heutige Stunden</p>
			</div>
			<div style={{  width: 100, height: 100, aspectRatio: '1/1', justifyContent: 'center', display: 'flex', flexDirection: 'column', borderRadius: 100,  borderWidth:4, borderColor: 'lightgrey' }}>
				<p style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', color: 'green'  }}>{statistics?.todayReady ?? '--'}</p>
				<p style={{ fontSize: 10, textAlign: 'center', color: 'gray' }}>Erledigte Tickets</p>
			</div>
		</div>

		{/*<div className="circle-wrap">*/}
		{/*	<div className="circle">*/}
		{/*		<div className="mask full">*/}
		{/*			<div className="fill"></div>*/}
		{/*		</div>*/}
		{/*		<div className="mask half">*/}
		{/*			<div className="fill"></div>*/}
		{/*		</div>*/}
		{/*		<div className="inside-circle"> 75%</div>*/}
		{/*	</div>*/}
		{/*</div>*/}

	</div>
  );
};

