import React, { useEffect, useState } from 'react';
import { RadioSelect } from './RadioSelect';
import { RadioOption } from '../interfaces/RadioOption';
import { Input } from './Input';
import axios from 'axios';
import Select from 'react-select';
import { Loading } from './Loading';
import { Route } from '../interfaces/Route';

export enum JourneyType {
  WKS,
  WKP,
}

interface Props {
  route: Route,
  name: string,
  id?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  onChange?: (route: Route) => void,
  setKilometer: (kilometer: string) => void,
  setRoute: (id: number, name: string) => void,
  setDriveOption: (option: RadioOption) => void,
  options: RadioOption[]
  type: JourneyType
  selectedRoute: Route
}

const typeFilter = (routes:Route[], option: JourneyType ): Route[] => {
  if (option === JourneyType.WKP) {
    return  routes.filter((r: Route) => r.name.startsWith('WK-P'));
  }
  return  routes.filter((r: Route) => r.name.startsWith('WK-S'));
};


export const CalculateJourney: React.FC<Props> = ({ route, name, onChange, setKilometer, setRoute, setDriveOption, options, type, disabled, selectedRoute }: Props) => {

  const [calcJourney, setCalcJourney] = useState<boolean>(true);

  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [routes, setRoutes] = useState<any>([]);
  const [routesLoading, setRoutesLoading] = useState<boolean>(false);

  const [required, setRequired] = useState<boolean>(false);


  useEffect(() => {
    console.log('type', type);
    setSelectedValue(null);
    setRoute(0, '');
  }, [type]);

  const onRadioChange = (item: any) => {

    console.log('onRadioChange', item);

    setCalcJourney(item.value === 'yes');

    setDriveOption(item);
  };

  useEffect(() => {
    onRadioChange(options[disabled ? 1 : 0]);
  }, [disabled]);

  const fetchRoutes = () => {

    setRoutesLoading(true);

    axios.get('/api/routes').then((response) => {

      console.log('response', response, response.data);

      const data = response.data;
      setRoutes(data);

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setRoutesLoading(false);
    });
  };

  useEffect(() => {
    fetchRoutes();
  }, []);


  useEffect(() =>  {
    console.log('selectedRoute', selectedRoute);
    if (selectedRoute.id === 0) setSelectedValue(null);
  }, [selectedRoute.id]);

  useEffect(() => {
    if (route.name == '' && routes.length > 0) {
      setRoute(routes[0].id, routes[0].name);
    }
  }, [routes]);

  const selectRoute = (element: any) => {

    console.log('Select route', element);
    setSelectedValue(element);

    setRequired(element.name.includes('Fahrtweg nach Kilometer'));

    setRoute(element.id, element.name);
  };

  return (
	<div className="relative border border-gray-300 rounded-b-none px-3 py-2">
		<RadioSelect disabled={disabled} header={'Anfahrtsweg berechnen'} options={options} onChange={onRadioChange} selectedValue={route.option} includeWrapperClassnames={false} />

		{ calcJourney &&
		<div className="flex items-end mt-2">

			{routesLoading &&
			<div className="relative">
				<Loading  />
			</div>
            }
			{!routesLoading &&
			<div className="w-80">
				<Select options={typeFilter(routes, type)}
                        placeholder={'Fahrtweg'}
                        getOptionLabel={(option: Route) => `${option.name}`}
                        getOptionValue={(option: Route) => option.id.toString()}
                        onChange={selectRoute}
                        value={selectedValue}
                        isDisabled={disabled}
                        isClearable={true}
                />
			</div>
            }

			{ calcJourney && route?.name.includes('Fahrtweg nach Kilometer')  &&
			<div className="ml-3">
				<Input label="" name={'km'} onChange={(event) => setKilometer(event.target.value)} value={route.kilometer} required={required}/>
			</div>
        }
		</div>
      }
	</div>
  );
};
