/**
 * Generate a random id
 * @See https://stackoverflow.com/a/1349426
 *
 * @param length
 */
export const generateId = (length = 8) => {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
  }
  return result;
};
