import React, { useCallback, useEffect, useState } from 'react';
import { Order } from '../../interfaces/Order';
import moment  from 'moment';
import { useAuth } from '../../auth/AuthProvider';
import axios from 'axios';

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import set from 'lodash.set';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'lodash.clonedeep';

import { RadioOption } from '../../interfaces/RadioOption';
import { Input } from '../../components/Input';
import { RadioSelect } from '../../components/RadioSelect';
import { InputStaff } from '../../components/InputStaff';
import { Date } from '../../components/Date';
import { CalculateJourney, JourneyType } from '../../components/CalculateJourney';
import { Material } from '../../components/Material';
import { Button } from '../../components/Button';
import { Signature } from '../../components/Signature';
import { SuccessModal } from '../../components/modals/SuccessModal';
import { ErrorModal } from '../../components/modals/ErrorModal';
import { ActivityForm } from '../../components/ActivityForm';
import { Staff } from '../../interfaces/User';
import { Textarea } from '../../components/Textarea';


interface Props {
}

let activityOptions: RadioOption[] = [
  {
    name: 'Vor Ort',
    value: 'on_site',
  },
  {
    name: 'Fernwartung',
    value: 'remote',
  },
  {
    name: 'Werkstatt',
    value: 'workshop',
  },
  {
    name: 'Installation',
    value: 'installation',
  },
  {
    name: 'Projektarbeit',
    value: 'project',
  },
];

const nextAppointmentOptions = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];


const notDoneOptions = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];

const driveOptions: RadioOption[] = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];

const initialOrder: Order = Object.freeze({
  id: 0,
  orderNumber: '',
  serviceDate: moment(),
  notDone: notDoneOptions[1],
  activity: '',
  subject: '',
  company: {
    id: 0,
    number: 0,
    name: '',
    customerSalesChannel:'',
  },
  material: {
    ics: [],
    mvz: [],
  },
  staff: [],
  signatures: {
    wk: '',
    customer: '',
  },
  activityForm: activityOptions[0],
  nextAppointment: nextAppointmentOptions[1],
  nextAppointmentAdditionalInfo: '',
  route: {
    id: 0,
    name: '',
    kilometer: '0',
    option: driveOptions[0],
  },
});


export const Ics: React.FC<Props> = () => {

  const [order, setOrder] = useState<Order>(cloneDeep(initialOrder));
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [actOptions, setActOptions] = useState<RadioOption[]>(activityOptions);
  const [nAOptions, setNAOptions] = useState<RadioOption[]>(nextAppointmentOptions);
  const [driOptions, setDriOptions] = useState<RadioOption[]>(driveOptions);

  const auth = useAuth();

  useEffect(() => {

    console.log('clone initial order');

    let tmpOrder = cloneDeep(initialOrder);

    tmpOrder.activityForm = actOptions[0];
    tmpOrder.nextAppointment = null;
    tmpOrder.route.option = driOptions[0];

    setOrder(tmpOrder);

  }, []);

  const fetchOrder = (event: any) => {
    console.log('fetch order', event.target.value, order);

    axios.get(`/api/order/${event.target.value}`).then((response) => {

      console.log('response', response, response.data);

      const data = response.data;
      setOrder({ ...order, ...data });

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setOrderLoading(false);
    });
  };

  const debouncedChangeHandler = useCallback(debounce(fetchOrder, 300), [order]);

  const handleChangeOrderNumber = (event: any) => {
    setOrderLoading(true);
    setOrder((prev) => ({ ...prev, orderNumber: event.target.value }));
    debouncedChangeHandler(event);
  };

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const handleValueChanged = (event: any, stateName: any) => {
    const val = event.target.value;

    // @See https://stackoverflow.com/questions/6393943/convert-a-javascript-string-in-dot-notation-into-an-object-reference
    setOrder((prev: Order) => {
      set(prev, stateName, val);
      return { ...prev };
    });
  };

  const onRadioChangeActivity = (value: any) => {
    console.log('on radio change', value);
    setOrder((prev) => ({ ...prev, activityForm: value }));
  };

  const onRadioChangeNextAppointment = (value: any) => {
    console.log('on radio change', value);
    setOrder((prev) => ({ ...prev, nextAppointment: value }));
  };

  const onJourneyChange = (value: RadioOption) => {
    setOrder((prev) => ({ ...prev, calculateJourney: value }));
  };

  const staffChanged = (item: any) => {
    if (order.staff.findIndex((staff) => staff.id === item.id) > -1) return;

    order.staff.push(item);
    setOrder({ ...order });
  };

  const removeStaff = (userId: any) => {
    console.log('remove staff', userId, order.staff.findIndex((el) => el.id === userId));
    order.staff.splice(order.staff.findIndex((el) => el.id === userId), 1);
    setOrder({ ...order });
  };

  const hoursChanged = (user: Staff) => {
    const index = order.staff.findIndex((el) => el.id === user.id);
    order.staff[index] = user;
    setOrder({ ...order });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    console.log(order);

    setSubmitting(true);

    axios.post('/api/protocols/ics', order)
      .then(response => {
        setStatus('success');
        console.log(response);

        const tmpOrder = cloneDeep(initialOrder);

        tmpOrder.activityForm = actOptions[0];
        tmpOrder.nextAppointment = nextAppointmentOptions[1];
        tmpOrder.route.option = driOptions[0];

        console.log('tmpOrder', tmpOrder, initialOrder);

        setOrder({ ...tmpOrder });

      }).catch(error => {
        setStatus('error');
        console.log(error);
      }).then(() => {
        setShowModal(true);
        setSubmitting(false);
      });

    return false;
  };

  const setIcsMaterial = (materials: any[]) => {
    order.material.ics = materials;
    setOrder({ ...order });
  };

  const setMvzMaterial = (materials: any[]) => {
    order.material.mvz = materials;
    setOrder({ ...order });
  };

  const setAdditionalInfoText = (info: string) => {
    order.nextAppointmentAdditionalInfo = info;
    setOrder({ ...order });
  };

  const setWkSignature = (data: any) => {
    order.signatures.wk = data;
    setOrder({ ...order });
  };

  const setCustomerSignature = (data: any) => {
    order.signatures.customer = data;
    setOrder({ ...order });
  };

  const handleDateChange = (date: any) => {
    setOrder((prev) => ({ ...prev, serviceDate: date }));
  };

  const setKilometer = (kilometer: string) => {
    order.route.kilometer = kilometer;
    setOrder({ ...order });
  };

  const setRoute = (id: number, name: string) => {
    order.route.id = id;
    order.route.name = name;
    setOrder({ ...order });
  };

  const setDriveOption = (item: RadioOption) => {
    order.route.option = item;
    if (item.value == 'no') {
      order.route.id = 0;
      order.route.name = '';
      order.route.kilometer = '0';
    }
    setOrder({ ...order });
  };

  const handleClose = () => {
    setStatus('');
    setShowModal(false);
  };

  return (
	<div className="bg-wk">

		<form action="" onSubmit={handleSubmit}>
			<div className="isolate -space-y-px shadow-sm">
				<div className="flex justify-center mb-3">
					<img src={process.env.REACT_APP_BASE_URL + '/ics_logo.png'} alt="" className="h-12 w-auto object-contain"/>
				</div>
				<Date label={'Leistungsdatum: *'} name={'serviceDate'} onChange={handleDateChange} value={order.serviceDate} required={true} />
				<Input label={'Weclapp Auftragsnummer: *'} name={'order_number'} required={true} onChange={handleChangeOrderNumber} value={order.orderNumber} loading={orderLoading} inlineStyling={false}/>
				<Input label={'Kunde: *'} name={'company.name'} required={true} value={order.company.name} onChange={handleValueChanged} inlineStyling={false}/>
				<Input label={'Thema:'} name={'company.name'} required={false} value={order.subject} readonly={true} inlineStyling={false}/>
				<InputStaff label={'Mitarbeiter Webkultur: *'} name={'wk_staff'} staffList={order.staff} onChange={staffChanged} removeStaff={removeStaff} hoursChanged={hoursChanged} />
				<Textarea label={'Tätigkeit: *'} name={'activity'} required={true} value={order.activity} onChange={handleValueChanged} />
				<Material  label={'Material von Webkultur:'} value={'mvz'} selectedMaterials={order.material.mvz} setSelectedMaterials={(data) => setMvzMaterial(data)}/>
				<Material label={'Material von ICS:'} value={'ics'} selectedMaterials={order.material.ics} setSelectedMaterials={(data) => setIcsMaterial(data) }/>
				<ActivityForm header={'Tätigkeitsform: *'} options={actOptions} onChange={onRadioChangeActivity} selectedValue={order.activityForm} includeWrapperClassnames={false} staff={order.staff}/>
				<CalculateJourney name={'journey'}
                                  route={order.route}
                                  selectedRoute={order.route}
                                  setKilometer={setKilometer}
                                  setRoute={setRoute}
                                  options={driOptions}
                                  type={((order.activityForm.value === 'on_site' || order.activityForm.value === 'project') && (order.company.customerSalesChannel === 'NET3' || order.company.customerSalesChannel === 'NET7')) ? JourneyType.WKP : JourneyType.WKS}
                                  setDriveOption={setDriveOption}/>
				<RadioSelect onAdditionalTextChange={setAdditionalInfoText} additionalInputEnabled={order.nextAppointment?.value === 'yes'} info={order.nextAppointment?.value === 'no' ? 'ACHTUNG: Ticket wird geschlossen' : ''} header={'Folgetermin nötig: *'} options={nAOptions} onChange={onRadioChangeNextAppointment} selectedValue={order.nextAppointment} />
				<Signature label={'Unterschrift Webkultur'} onSave={setWkSignature} data={order.signatures.wk}/>
				<Signature label={'Unterschrift Kunde'} onSave={setCustomerSignature} data={order.signatures.customer} />

				<Button type={'submit'} label={'Speichern'} disabled={submitting} loading={submitting} classNames="w-full mt-4" />

			</div>
		</form>

		{status == 'success' &&
		<SuccessModal open={showModal} onClose={handleClose} />
        }
		{status == 'error' &&
		<ErrorModal open={showModal} onClose={handleClose} />
        }

	</div>
  );
};
