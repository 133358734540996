import React from 'react';

import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  label: string,
  data: any[],
  onSelection: (item: any) => void,
  selected: any,
  loading: boolean
}

export const SearchInput: React.FC<Props> = ({ label, data, onSelection, selected, loading }: Props) => {
  const [query, setQuery] = useState('');

  const filteredPeople =
        query === ''
          ? data
          : data.filter((item) =>
            item.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          );

  return (
	<div className="relative">
		<label className="block text-xs font-bold text-gray-900">
			{label}
		</label>
		{loading &&
		<span>Lade Daten bitte warten</span>
        }
		{!loading &&
		<div className={'relative'}>
			<Combobox value={selected} as="div"
                  style={{ zIndex: 100 }}
                  className="relative mx-auto max-w-xl transform rounded-xl bg-white transition-all"
                  onChange={onSelection}
        >
				<Combobox.Input
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                            placeholder="Suchen..."
                            displayValue={(item: any) => item.name}
                            onChange={(event) => setQuery(event.target.value)}
                        />

				{data.length > 0 && (
				<Combobox.Options as={'div'} className=" left-0 right-0 top-10 -mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 w-full bg-white border rounded-md">
					{data.map((item) => (
						<Combobox.Option
                            as={'div'}
                            key={item.id}
                            value={item}
                            className={({ active }) =>
                              classNames(
                                'cursor-default select-none rounded-md px-4 py-2',
                                active && 'bg-indigo-600 text-white',
                              )
                        }
                        >
							{item.name}
						</Combobox.Option>
					))}
				</Combobox.Options>
				)}

				{data.length === 0 && (
				<div className="relative">
					<p className="text-sm text-red-600">Keine Daten gefunden</p>
				</div>
				)}
			</Combobox>
		</div>
        }
	</div>
  );
};
