// @ts-nocheck
import React from 'react';
import { RadioOption } from '../interfaces/RadioOption';
import { RadioSelect } from './RadioSelect';
import { User } from '../interfaces/User';

interface Props {
  header: string,
  options: RadioOption[],
  onChange: (value: any) => void,
  selectedValue: any,
  includeWrapperClassnames?: boolean,
  staff: User[]
}

export const ActivityForm: React.FC<Props> = ({ header, options, onChange, selectedValue, includeWrapperClassnames = true, staff }: Props) => {

  const handleChange = (value: RadioOption) => {
	  onChange(value);
  };

  return (
	  <div className='relative border border-gray-300 rounded-b-none border-b-0 px-3 py-2'>
		<RadioSelect header={header} options={options} onChange={handleChange} selectedValue={selectedValue} includeWrapperClassnames={includeWrapperClassnames}/>
		  {staff.map((user, index) => {
		    let data = [];

		    const classNames = 'relative py-2 text-sm text-red-600';

		    if (selectedValue.value === 'on_site' && user.services.service.id == null)
		      data.push(<div key={index} className={classNames}>Für {user.firstname} {user.lastname} ist keine Dienstleistung hinterlegt</div>);
		    if (selectedValue.value === 'remote' && user.services.remote.id == null)
		      data.push(<div key={index} className={classNames}>Für {user.firstname} {user.lastname} ist keine Fernwartung hinterlegt</div>);
		    if (selectedValue.value === 'workshop' && user.services.workshop.id == null)
		      data.push(<div key={index} className={classNames}>Für {user.firstname} {user.lastname} ist keine Werkstatt hinterlegt</div>);
		    if (selectedValue.value === 'installation' && user.services.installation.id == null)
		      data.push(<div key={index} className={classNames}>Für {user.firstname} {user.lastname} ist keine Installation hinterlegt</div>);
		    if (selectedValue.value === 'project' && user.services.project.id == null)
		      data.push(<div key={index} className={classNames}>Für {user.firstname} {user.lastname} ist kein Projekt hinterlegt</div>);
		    return data;
		  })}
	  </div>
  );
};
