import React, { useState } from 'react';

import { Dialog } from '@headlessui/react';

interface Props {
  type?: string,
  onConfirm: () => void,
  onCancel: () => void,
  cancelLabel?: string,
  confirmLabel: string,
  header: string,
  body: string,
  open: boolean,
  disabled?: boolean,
  loading?: boolean
}

export const AskModal: React.FC<Props> = ({ header, body, confirmLabel, cancelLabel = 'Abbruch', onConfirm, onCancel, type = 'error', open, disabled = false, loading = false }: Props) => {

  return (
	<Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => onCancel()} open={open}>
		<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
				&#8203;
			</span>
			<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
				<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
					<button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => onCancel()}
                  >
						<span className="sr-only">Close</span>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div className="sm:flex sm:items-start">
					<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
						</svg>
					</div>
					<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
							{header}
						</Dialog.Title>
						<div className="mt-2">
							<p className="text-sm text-gray-500">
								{body}
							</p>
						</div>
					</div>
				</div>
				<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
					<button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      disabled={disabled}
                      onClick={() => onConfirm()}
                  >
						{ loading &&
						<svg  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
                    }
						{confirmLabel}
					</button>

					<button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => onCancel()}
                  >
						{cancelLabel}
					</button>
				</div>
			</div>
		</div>
	</Dialog>
  );
};
