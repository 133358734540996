// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { RadioOption } from '../interfaces/RadioOption';
import classNames from 'classnames';
import { Textarea } from './Textarea';

function classNamess(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  header: string,
  options: RadioOption[],
  onChange: (value: any) => void,
  selectedValue: any,
  includeWrapperClassnames?: boolean
  disabled?: boolean
  info?: string
  additionalInputEnabled?: boolean
  onAdditionalTextChange?: (text: string) => void
}

export const RadioSelect: React.FC<Props> = ({ header, options, onChange, selectedValue, includeWrapperClassnames = true, disabled, info, additionalInputEnabled, onAdditionalTextChange = () => {} }: Props) => {

  const [additionalText, setAdditionalText] = useState<string>('');

  useEffect(() => {
    console.log(additionalText);
    if (onAdditionalTextChange) onAdditionalTextChange(additionalText);
  }, [additionalText]);

  //
  useEffect(() => {
    if (selectedValue === null) setAdditionalText('');
  }, [selectedValue]);

  const handleChange = (value: RadioOption) => {
	  onChange(value);
  };

  let gridClass = 'grid';
  if (options.length === 2) {
	  gridClass += ' grid-cols-2';
  } else if (options.length === 3) {
	  gridClass += ' grid-cols-3';
  } else if (options.length === 4) {
	  gridClass += ' grid-cols-4';
  } else {
    gridClass += ' grid-cols-3';
  }

  return (
	<div className={classNames({
	  'relative border border-gray-300 rounded-b-none border-b-0 px-3 py-2': includeWrapperClassnames,
	})}>
		<div className="flex items-center justify-between">
			<h2 className="text-sm font-bold text-gray-900">{header}</h2>
		</div>

		<RadioGroup value={selectedValue} onChange={handleChange} className="mt-2">
			<div className={gridClass}>
				{options.map((option) => (
					<RadioGroup.Option
                    key={option.value}
                    disabled={disabled}
                    value={option}
					className={({ active, checked }) =>
					  classNamess(
					    active ? 'active ring-1 ring-offset-1 ring-wk-app' : '',
					    checked
					      ? 'checked bg-wk-app border-transparent text-white hover:bg-wk-700'
					      : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
					    'border py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer select-none',
					  )
                    }
                >
						<RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
		{info && <p style={{ color: 'orange', marginTop:5 }}>{info}</p>}
		{additionalInputEnabled && <div style={{ marginTop: 5 }}>
			<Textarea label={''} value={additionalText} name={'dsd'}  placeholder={'Zusätzliche Info'}  onChange={(r, s) => setAdditionalText(r.target.value)} />
		</div>}


	</div>
  );
};
