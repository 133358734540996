import React, { useEffect } from 'react';
import { AuthContextType } from './AuthContextType';
import { sanctumAuthProvider } from './auth';
import axios from 'axios';

interface Props {
  children: React.ReactNode
}

// @ts-ignore
export const AuthContext = React.createContext<AuthContextType>(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider: React.FC<Props> = (props: Props) => {

  const [user, setUser] = React.useState<any>(null);

  useEffect(() => {
    console.log('useEffect Auth');
    const u = localStorage.getItem('user');
    console.log(u);
    if (u) {
      console.log('set user', u);
      const data = JSON.parse(u);
      setUser(data);
      axios.defaults.headers.common = { 'Authorization': `Bearer ${data.token}` };

    }
  }, []);

  const signin = async (email: string, password: string, callback: (user: any) => void) => {
    console.log('auth login');
    return sanctumAuthProvider.signin(email, password, (u) => {
      console.log('set user', u);
      setUser(u);
      localStorage.setItem('user', JSON.stringify(u));
      callback(u);
      console.log('set user after callback');
    });
  };

  const signout = (callback: VoidFunction) => {
    return sanctumAuthProvider.signout(() => {
      console.log('signout');
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };
  return (
	<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
