import React  from 'react';

import { Dialog } from '@headlessui/react';
import SignatureCanvas from '../SignatureCanvas';
import { Button } from '../Button';

interface Props {
  type?: string,
  onConfirm: (data: any) => void,
  onCancel: () => void,
  cancelLabel?: string,
  confirmLabel: string,
  header: string,
  body: string,
  open: boolean,
  disabled?: boolean,
  loading?: boolean
}

export const SignatureModal: React.FC<Props> = ({ header, body, confirmLabel, cancelLabel = 'Abbruch', onConfirm, onCancel, type = 'error', open, disabled = false, loading = false }: Props) => {

  let sigPad: any;

  const clear = () => {
    sigPad.clear();
  };

  const handleClick = () => {
    const data = sigPad.getTrimmedCanvas().toDataURL('image/png');
    onConfirm(data);
  };

  return (
	<Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => onCancel()} open={open}>
		<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
				&#8203;
			</span>
			<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
				<div className="flex justify-center">
					<SignatureCanvas canvasProps={{ height: 150, width: 450, className: 'sigCanvas border' }}
                                     ref={(ref) => { sigPad = ref; }}/>
				</div>

				<div className="mt-4 flex justify-end">
					<Button label={confirmLabel} onClick={handleClick} />

					<button
                      type="button"
                      className="mr-2 ml-2 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => onCancel()}
                  >
						{cancelLabel}
					</button>
				</div>
			</div>
		</div>
	</Dialog>
  );
};
