import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Staff } from '../interfaces/User';
import { Loading } from './Loading';
import { useAuth } from '../auth/AuthProvider';
import { Input } from './Input';

interface Props {
  label: string,
  name: string,
  staffList: Staff[],
  placeholder?: string,
  onChange: (value: any) => void,
  removeStaff: (value: any) => void
  hoursChanged: (value: any) => void
}

export const InputStaff: React.FC<Props> = ({ label, name, staffList = [], placeholder, onChange, removeStaff, hoursChanged }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [staff, setStaff] = useState<Staff[]>([]);

  const authUser =  useAuth();
  console.log('stafflist', staffList);

  const loadStaff = () => {
    setLoading(true);

    axios.get('/api/staff').then((response) => {

      console.log('staff response', response, response.data);

      let data = response.data.data;
      data = data.map((obj: any) => ({ ...obj, hours: 1.0 }));

      console.log('data', data);
      setStaff(data);

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadStaff();
  }, []);

  useEffect(() => {
    staff.forEach((user: any) => {
      if (user.id == authUser.user.id) {
        console.log('user found', user);
        onChange(user);
      }
    });
  }, [staff]);

  const onInternalChange = (event: any) => {
    if (event.target.value == -1) return;

    const index = staff.findIndex((el) => el.id == event.target.value);
    onChange(staff[index]);
  };

  const changeHours = (event: any, user: Staff) => {
    console.log('changeHours', event.target.value, user);
    user.hours = event.target.value;
    hoursChanged(user);
  };

  return (
	<div className="relative border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
		<div className="flex flex-row justify-between">
			<div className="block text-xs font-bold text-gray-900">
				{label}
			</div>
		</div>
		<div className="text-sm text-gray-900 flex mt-2">
			{loading &&
			<div>
				<Loading/>
			</div>
            }
		</div>
		<div>
			{ !loading &&
				<select name="" id="" value={'-1'} onChange={onInternalChange} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
					<option value="-1" className="text-gray-500">Mitarbeiter auswählen</option>
					{staff.map((user, index) => {
				  return <option key={index} value={user.id}>{user.firstname} {user.lastname}</option>;
					})}
				</select>
            }
			<table className="w-full divide-y divide-gray-300 block">
				<tbody className="divide-y divide-gray-200">
					{staffList.map((user, index) => {
					  return <tr key={'user-list-id-' + user.id}>
						<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{user.firstname} {user.lastname}</td>
						<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
							<Input label={'Stundenzahl: *'} name={'hours'} required={true} value={user.hours.toString()} type={'number'} pattern="[0-9]+([\.,][0-9]+)?" step={'0.25'} onChange={(event) => changeHours(event, user)} inlineStyling={false}/>
						</td>
						<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
							<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => removeStaff(user.id)}>
								Löschen
							</a>
						</td>
					</tr>;
					})}
				</tbody>
			</table>
		</div>
	</div>
  );
};
