import React from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
}

export const Header: React.FC<Props> = () => {
  let auth = useAuth();
  let navigate = useNavigate();
  let location = useLocation();

  const showBackArrow = () => {
    return location.pathname !== '/' &&  location.pathname !== '/login';
  };

  return (
	<>
		{auth.user?.isAuthenticated &&
		<header className="p-4 bg-gray-600 text-white flex justify-between">
			<div>
				{ showBackArrow() &&
				<button onClick={() => navigate(-1)}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
						<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"/>
					</svg>
				</button>
          }
			</div>


			<div>
				<button onClick={() => navigate('/')}>
					<img src={process.env.REACT_APP_BASE_URL + '/mvz_logo_klein.png'} alt="Logo" className="h-6"/>
				</button>
			</div>
			<button type='button' className="flex items-center justify-center" onClick={() => {auth.signout(() => navigate('/'));}}>
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth="2">
					<path strokeLinecap="round" strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
				</svg>
				<span className="ml-1">Logout</span>
			</button>

		</header>
      }
	</>
  );
};
