import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../../auth/AuthProvider';

import axios from 'axios';
import { Staff } from '../../interfaces/User';
import { Loading } from '../../components/Loading';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { stat } from 'fs';
import moment from 'moment';

interface Props {
}

export const Statistics: React.FC<Props> = () => {


  const [statistics, setStatistics] = useState<[]>([]);
  const [filteredStatistics, setFilteredStatistics] = useState<[]>([]);
  const [sum, setSum] = useState<any>({
	  hours: '0',
	  km: '0',
	  internHours: '0',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [staffLoading, setStaffLoading] = useState<boolean>(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [groupBy, setGroupBy] = useState<string>('day');

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  const auth = useAuth();

  const loadStaff = () => {
    setStaffLoading(true);

    axios.get('/api/staff').then((response) => {

      console.log('staff response', response, response.data);

      let data = response.data.data;
      data = data.map((obj: any) => ({ ...obj, hours: 1.0 }));

      console.log('data', data.filter((r:any) => r.id == auth.user.id));
      setStaff(data);
	  const ownUser = data.filter((r:any) => r.id == auth.user.id);
	  if (ownUser.length === 1) {

	  setSelectedStaff(ownUser[0]);
	  }
    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setStaffLoading(false);
    });
  };

  useEffect(() => {
    loadStaff();
  }, []);

  const fetchStatistics = () => {

	  setStatistics([]);

	  setLoading(true);

	  let userId = '';
	  if (selectedStaff) {
		  userId = '&user_id=' + selectedStaff.id;
	  }

	  axios.get('/api/admin/statistics?groupBy=' + groupBy + userId).then((response) => {

		  //console.log('response', response.data);

		  // @ts-ignore
		  window.statistics = response.data;

		  setStatistics(response.data);

	  }).catch((error) => {
		  console.log(error	);
	  }).then(() => {
		  setLoading(false);
	  });
  };

  // useEffect(() => {
  //   fetchStatistics();
  // }, []);

  useEffect(() => {
	  if (!selectedStaff && statistics.length === 0) return;
	  console.log('fetching', groupBy, selectedStaff );
	  fetchStatistics();
  }, [groupBy, selectedStaff]);

  const selectStaff = (event: ChangeEvent<HTMLSelectElement>) => {
	  console.log('selectStaff', event.target.value);
	  if (event.target.value == '-1') {
		  setSelectedStaff(null);
		  return;
	  }

	  console.log('found staff', staff.find((el) => el.id.toString() == event.target.value));
	  setSelectedStaff(staff.find((el) => el.id.toString() == event.target.value) ?? null);
  };

  const filterStatistics = () => {

	  let format = 'DD.MM.YYYY';
	  if (groupBy === 'week') {
      	format = '[KW] ww YYYY';
	  } else if (groupBy === 'month') {
		  format = 'MMM YYYY';
	  }

	  let hours: number = 0;
	  let internHours: number = 0;
	  let km: number = 0;
	  console.log('statistics', statistics);

	  const arr = statistics.filter((el: any) => {
		  if (startDate === null || endDate === null)
			  return true;

		  const date = moment(el.date, format);

		  // @ts-ignore
		  console.log('isTrue', date.isSameOrAfter(moment(startDate), groupBy), date.isSameOrBefore(moment(endDate), groupBy), el.date, date);
		  // @ts-ignore
		  return date.isSameOrAfter(moment(startDate), groupBy) && date.isSameOrBefore(moment(endDate), groupBy);
	  });

	  for (let i = 0; i < arr.length; i++) {
		  // @ts-ignore
		  if (arr[i].intern) {
			  // @ts-ignore
			  internHours += arr[i].hours;
		  } else {
			  // @ts-ignore
		 	 hours += arr[i].hours;
		  }
		  // @ts-ignore
		  km += arr[i].kilometer;
	  }

	  console.log(hours, km);
	  setSum({
		  hours: hours.toFixed(2),
		  km: km.toFixed(2),
		  internHours: internHours.toFixed(2),
	  });

	  // @ts-ignore
	  setFilteredStatistics([...arr] );
  };

  useEffect(() => {
    filterStatistics();
  }, [statistics]);

  useEffect(() => {
	  console.log('ue', startDate, endDate);
	  if ((startDate !== null && endDate !== null) || (startDate === null && endDate === null))
		  filterStatistics();

  }, [startDate, endDate]);

  return (
	<div className="bg-wk">

		<div className="flex flex-col">
			<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

					<div className={'flex flex-row justify-between'}>
						<div>
							{staffLoading &&
								<div>
									<Loading/>
								</div>
							}
							{!staffLoading &&
								<select name="" id="" onChange={(event) => selectStaff(event)}
									autoComplete={'off'} disabled={loading} defaultValue={selectedStaff?.id}
									className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
									<option value="-1" className="text-gray-500">Alle</option>
									{staff.map((user, index) => {
						  			return <option key={'staff-' + index} value={user.id}>{user.firstname} {user.lastname}</option>;
									})}
								</select>
							}
						</div>

						<div className={'flex flex-row'}>
							<DatePicker
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								calendarStartDay={1}
								maxDate={new Date()}
								onChange={(update) => {
								  setDateRange(update);
								}}
								isClearable={true}
								placeholderText={'Datum auswählen'}
								dateFormat={'dd.MM.yyyy'}
								className={'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'}
							/>

							<div className="flex justify-end">
								<span className="relative z-0 inline-flex shadow-sm rounded-md">
									<button type="button" onClick={() => setGroupBy('day')} disabled={loading}
									className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
								>
										Tag
									</button>
									<button type="button" onClick={() => setGroupBy('week')} disabled={loading}
									className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
								>
										Woche
									</button>
									<button type="button" onClick={() => setGroupBy('month')} disabled={loading}
									className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
								>
										Monat
									</button>
								</span>
							</div>
						</div>
					</div>

					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
									Name
								</th>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
									Ticket
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Datum
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Abrechenbare Stunden
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Interne Stunden
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Kilometer
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{loading &&
								<tr>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500" colSpan={4}>Lade Daten, bitte warten....</td>
								</tr>
							}
							{!loading &&

								filteredStatistics.map((entry: any, index: number) => {

								  return (<tr key={'stats-' + index}>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.title}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.order_number}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.date}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.intern ? '' : entry.hours}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.intern ?  entry.hours : ''}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{entry.kilometer}</td>
								</tr>);


								})
						}
						</tbody>
						<tfoot className="divide-y divide-gray-200">
							<tr>
								<td colSpan={3} className={'text-right pr-4 text-gray-600 font-bold'}>Summe:</td>
								<td className={'text-left text-gray-600 font-bold'}>{sum.hours} Std</td>
								<td className={'text-left text-gray-600 font-bold'}>{sum.internHours} Std</td>
								<td className={'text-left text-gray-600 font-bold'}>{sum.km} km</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>

	</div>
  );
};
