import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './auth/AuthProvider';
import { ProtectedRoutes } from './auth/ProtectedRoutes';
import { Home } from './router/Home';
import { Login } from './router/Login';
import { Protected } from './router/Protected';
import './index.css';
import axios from 'axios';

import { Ics } from './router/protocols/Ics';
import { Mvz } from './router/protocols/Mvz';
import { Users } from './router/management/Users';
import { Statistics } from './router/management/Statistics';
import { Protocols } from './router/management/Protocols';
import moment from 'moment';
import { Open } from './router/tickets/Open';

moment.locale('de');

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(function (config) {
  // @ts-ignore
  config.headers['Accept'] = 'application/json';
  return config;
});
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log('response interceptor', response);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
	  // @ts-ignore
	  window.location = '/login';
	  return;
	 // alert('Unauthorized');
    //return;
  }
  console.log('catch interceptor', error.response.status);
  return Promise.reject(error);
});

const container = document.getElementById('root');
const root = createRoot(container as Element);

// @ts-ignore
let from = location.state?.from?.pathname || '/';

console.log('index from', from);

root.render(
	<AuthProvider>
		<BrowserRouter>
			<Routes>
				<Route element={<App />} >
					<Route path="/login" element={<Login />} />
					<Route element={<ProtectedRoutes redirectPath={'/login'}/>}>
						<Route path="/" element={<Home />} />
						<Route path="protected" element={<Protected />} />
						<Route path="protocols/ics" element={<Ics />} />
						<Route path="protocols/mvz" element={<Mvz />} />
						<Route path="users" element={<Users />} />
						<Route path="statistics" element={<Statistics />} />
						<Route path="protocols" element={<Protocols />} />
						<Route path="tickets/open" element={<Open />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	</AuthProvider>
  ,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
