import React, { useEffect, useState } from 'react';
import { generateId } from '../helper/StringHelper';
import moment, { Moment } from 'moment';

interface Props {
  label: string,
  name: string,
  id?: string,
  value: Moment | null,
  required?: boolean,
  disabled?: boolean,
  errorMessage?: string,
  onChange?: (event: any) => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Date: React.FC<Props> = ({ label, name, id, value, required = false, disabled = false, onChange, errorMessage }: Props) => {

  id = id ?? generateId();

  const [date, setDate] = useState<string>();

  useEffect(() => {
    if (value) setDate(value.format('YYYY-MM-DD') ?? '');
    else setDate('');
  }, [value]);

  const changeValue = (event: any) => {
    console.log('change value', event);
    setDate(event.target.value);

  };

  const onBlur = () => {
    if (onChange) {
      onChange(moment(date));
    }
  };


  return (
	<div className="relative border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
		<label htmlFor={id} className="block text-xs font-bold text-gray-900">
			{label}
		</label>
		<input
                type="date"
                name={name}
                id={id}
                onBlur={onBlur}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                value={date}
                required={required}
                disabled={disabled}
                onChange={changeValue}
                autoComplete="off"
            />
		{errorMessage && <span className="text-xs  text-red-500">{errorMessage}</span>}
	</div>
  );
};
