import React, { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import { useAuth } from '../../auth/AuthProvider';
import { Pagination } from '../../components/Pagination';

interface Props {
}

export const Protocols: React.FC<Props> = () => {

  const [protocols, setProtocols] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState<any>({
	  current_page: 1,
	  total: 1,
  });

  const fetchProtocols = (page: number) => {

	  setProtocols({});

	  setLoading(true);
	  axios.get('/api/protocols?page=' + page).then((response) => {

		  console.log('response', response.data);

		  setProtocols(response.data.data);
		  setMeta(response.data.meta);

	  }).catch((error) => {
		  console.log(error	);
		  alert('Fehler beim laden der Protokolle');
	  }).then(() => {
		  setLoading(false);
	  });
  };

  useEffect(() => {
	    fetchProtocols(1);
  }, []);

  const navigatePrevious = () => {
	  console.log('navigatePrevious');
	  if (meta.current_page > 1) {
		  fetchProtocols(meta.current_page - 1);
		  setMeta({ ...meta, current_page: meta.current_page - 1 });
	  }
  };

  const navigateNext = () => {
	  console.log('navigateNext');
	  if (meta.current_page < meta.total) {
		  fetchProtocols(meta.current_page + 1);
		  setMeta({ ...meta, current_page: meta.current_page + 1 });
	  }
  };

  return (
	<div className="bg-wk">

		<div className="flex flex-col">
			<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
							>
									Name
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
									Erstellt am
								</th>
								<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">

								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{loading &&
							<tr>
								<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500" colSpan={3}>Lade Daten, bitte warten....</td>
							</tr>
						}
							{!loading &&

								protocols.map((protocol: any, index: number) => {

								    return (<tr key={index}>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{protocol.name}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{moment(protocol.created_at).format('dd, DD.MM.YYYY HH:mm')}</td>
									<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
										<a href={protocol.url} target="_blank" className={'text-blue-600 inline-flex justify-center items-center gap-1'} rel="noreferrer">
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
												<path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"/>
											</svg>
											<span>Download</span>
										</a>
									</td>
								</tr>);

								})
						}
						</tbody>
					</table>
				</div>

				<Pagination previousPage={navigatePrevious} nextPage={navigateNext} currentPage={meta.current_page} totalPages={meta.total}/>
			</div>
		</div>

	</div>
  );
};
