import axios from 'axios';

export const sanctumAuthProvider = {

  user: {
    isAuthenticated: false,
    username: '',
    role: 'user',
    token: '',
    service: {},
  },

  signin: async (email: string, password: string, callback: (user: any) => void) => {
    console.log('sanctum login');

    console.log('axios defaults', axios.defaults.withCredentials);
    // DO post request

    axios.post('/api/login', {
      email: email,
      password: password,
    }).then(response => {

      console.log('response', response);

      let user = response.data;
      user['isAuthenticated'] = true;
      user['token'] = response.data.token;

      axios.defaults.headers.common = { 'Authorization': `Bearer ${response.data.token}` };

      callback(user);

    });

    console.log('sanctum login after callback');

    throw new Error('Login error');
  },

  signout(callback: VoidFunction) {
    console.log('auth logout');
    localStorage.removeItem('user');
    callback();
  },
};
