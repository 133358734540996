import React from 'react';
import { generateId } from '../helper/StringHelper';

interface Props {
  label: string,
  data: any[],
  id?: string,
  onSelect: (item: any) => void
}

export const Select: React.FC<Props> = ({ label, data, id = generateId(), onSelect }: Props) => {

  const defaultValue = () => {
    return data[data.findIndex((el) => el.selected)].value;
  };

  return (
	<div>
		<label htmlFor={id} className="block text-sm font-medium text-gray-700">
			{label}
		</label>
		<select
            id={id}
            name="select"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            onChange={onSelect}
            defaultValue={defaultValue()}
        >
			{data.map((item, index) => {
			  return (<option key={index} value={item.value}>{item.name}</option>);
			})}
		</select>
	</div>
  );
};
