import React, { useRef, useState } from 'react';
import { SignatureModal } from './modals/SignatureModal';
import { on } from 'cluster';

interface Props {
  label: string,
  data: any,
  onSave: (data: any) => void
}

export const Signature: React.FC<Props> = ({ label, data, onSave }: Props) => {

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const onConfirm = (value: any) => {
    setModalOpen(false);
    onSave(value);
  };

  const removeSignature = () => {
    onSave(null);
  };

  return (
	<div className="relative border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
		<div className="flex flex-row justify-between">
			<div className="block text-xs font-bold text-gray-900">
				{label}
			</div>
		</div>
		<div className="text-sm text-gray-900 flex mt-2 flex-col">
			{data &&
			<button type="button" onClick={removeSignature} className="self-end text-red-500" title="Unterschrift entfernen">
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
				</svg>
			</button>
			}
			<div onClick={openModal} className="w-full border cursor-pointer h-24">
				{data
			  ? <div className="h-full">
				<img alt='signature' src={data} className="h-full" />
			</div>
			  : <div className="flex justify-center items-center h-full"><span>Unterschrift hinzufügen</span></div>}
			</div>

			<SignatureModal confirmLabel={'Übernehmen'} onConfirm={onConfirm} onCancel={() => setModalOpen(false)}
                open={modalOpen} header={label} body={''}/>

		</div>
	</div>
  );
};
