import React from 'react';

interface Props {
}

export const Protected: React.FC<Props> = () => {

  return (
	<div>protected page</div>
  );
};
