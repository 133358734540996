import React, { useEffect, useState } from 'react';

import { Material as Mat } from '../interfaces/Material';
import axios from 'axios';
import { Autocomplete } from './Autocomplete';

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'lodash.clonedeep';
import { Textarea } from './Textarea';

interface Props {
  label: string,
  value: string,
  selectedMaterials: Mat[],
  setSelectedMaterials: (materials: Mat[]) => void
}

export const Material: React.FC<Props> = ({ label, value, selectedMaterials, setSelectedMaterials }: Props) => {

  const [materialList, setMaterialList] = useState<Mat[]>([]);
  // const [selectedMaterials, setSelectedMaterials] = useState<Mat[]>([]);

  const fetchMaterial = async () => {


    // TODO: Cache not working
    axios.get('/api/materials?type=' + value, {
      // @ts-ignore
      cache: {
        // The time until the cached value is expired in milliseconds.
        ttl: 1000 * 60 * 60,

        // If the request should configure the cache based on some standard cache headers, Like
        // Cache-Control, Expires and so on...
        interpretHeader: false,

        // All methods that should activate cache behaviors. If the method is not in this list,
        // it will be completely ignored.
        methods: ['get'],

        // A predicate object that will be used in each request to determine if the request can
        // be cached or not.
        //
        // https://axios-cache-interceptor.js.org/#/pages/per-request-configuration?id=cachecachepredicate
        cachePredicate: {
          statusCheck: (status: any) => status >= 200 && status < 400,
        },

        // All requests that should have their cache updated once this request is resolved.
        // Normally used to update similar requests or records with newer data.
        //
        // https://axios-cache-interceptor.js.org/#/pages/per-request-configuration?id=cacheupdate
        update: {},

        // If the support for ETag and If-None-Match headers is active. You can use a string to
        // force a custom value for the ETag response.
        //
        etag: false,

        // If we should interpret the If-Modified-Since header when generating a TTL value.
        modifiedSince: false,

        // If we should return a old (possibly expired) cache when the current request failed
        // to get a valid response because of a network error, invalid status or etc.
        staleIfError: false,

      },
    }).then((response) => {
      console.log('resp', response);

      setMaterialList(response.data);

    }).catch(error => {
      console.log('err', error);
    });

  };

  useEffect(() => {
    fetchMaterial();
  }, []);

  const handleMaterialSelection = (element: any) => {

	  console.log('material selection', element	);

	  element.quantity = 1;
	  element.alreadyAvailable = false;

	  const newEl = cloneDeep(element);

	  setSelectedMaterials([...selectedMaterials, newEl]);
  };

  const removeMaterial = (index: number) => {
	  console.log('remove', index);

	  selectedMaterials.splice(index, 1);

	  console.log('spliced', selectedMaterials);

	  setSelectedMaterials(selectedMaterials);
  };

  const updateMaterialCount = (event: any, index: number) => {

	  console.log('updateMaterialCount', index);
	  const material = selectedMaterials[index];
	  console.log('material', material);
	  material.quantity = event.target.value;

	  selectedMaterials[index] = material;

	  console.log(selectedMaterials);

	  setSelectedMaterials([...selectedMaterials]);
  };

  const updateAvailability = (event: any, index: number) => {
	  console.log('updateAvailability', index);
	  const material = selectedMaterials[index];
	  material.alreadyAvailable = !material.alreadyAvailable;

	  selectedMaterials[index] = material;

	  console.log('asd', selectedMaterials);

	  setSelectedMaterials([...selectedMaterials]);
  };

  const updateAdditionalData = (event: any, index: number) => {
	  console.log('updateAdditionalData', index);
	  const material = selectedMaterials[index];
	  material.additionalData = event.target.value;

	  selectedMaterials[index] = material;

	  console.log('asd', selectedMaterials);

	  setSelectedMaterials([...selectedMaterials]);
  };

  return (
	<div className="relative border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
		<div className="flex flex-row justify-between">
			<div className="block text-xs font-bold text-gray-900">
				{label}
			</div>
		</div>
		<div className="text-sm text-gray-900 flex flex-col mt-2">

			<div>
				<Autocomplete data={materialList} searchProperty={'name'} onSelect={handleMaterialSelection} />
			</div>

			<div className="mt-4 mb-2 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                    >
										Name
									</th>
									<th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
										Anzahl
									</th>
									<th style={{ minWidth: '300px' }}>
										Weitere Infos
									</th>
									<th>
										Bereits vorhanden
									</th>
									<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{selectedMaterials.map((material, index) => (
									<tr key={index}>
										<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
											{material.name}
										</td>
										<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
											<input type="number" className="w-20" value={material.quantity} onChange={(event: any) => updateMaterialCount(event, index)}/>
										</td>
										<td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-center">
											<Textarea label={''} name={'additional_data'} value={material.additionalData} onChange={(event: any) => updateAdditionalData(event, index)}/>
										</td>
										<td className="whitespace-nowrap py-4 px-3 text-sm- text-gray-500 text-center">
											<input
												name="available"
												type="checkbox"
												className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
												checked={material.alreadyAvailable}
												onChange={(event: any) => updateAvailability(event, index)}
											/>
										</td>
										<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
											<a onClick={() => removeMaterial(index) } className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
												Löschen
											</a>
										</td>
									</tr>
								))}
								{selectedMaterials.length === 0 &&
									<tr>
										<td colSpan={4} className="text-center font-bold pt-2">Noch kein Material ausgewählt</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	</div>
  );
};
