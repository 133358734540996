import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Service, User } from '../../interfaces/User';
import { Input } from '../Input';


import axios from 'axios';
import { SearchInput } from '../SearchInput';
import { Button } from '../Button';
import { Select } from '../Select';

interface Props {
  open: boolean,
  onClose: () => void,
  user?: User | null,
  onClick: (user: User) => void,
  modalMode: string
}

export const UserModal: React.FC<Props> = ({ open, onClose, user, onClick, modalMode }: Props) => {


  const [roles, setRoles] = useState<any[]>([
    {
      name: 'admin',
      value: 'admin',
      selected: false,
    },
    {
      name: 'user',
      value: 'user',
      selected: true,
    },
  ]);


  const [firstname, setFirstname] = useState<string>(user?.firstname ?? '');
  const [lastname, setLastname] = useState<string>(user?.lastname ?? '');
  const [email, setEmail] = useState<string>(user?.email ?? '');
  const [role, setRole] = useState<string>(user?.role ?? roles[1].value);
  const [password, setPassword] = useState<string>('');
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [dataLoadingUser, setDataLoadingUser] = useState<boolean>(false);
  const [dataLoadingRemote, setDataLoadingRemote] = useState<boolean>(false);
  const [dataLoadingWorkshop, setDataLoadingWorkshop] = useState<boolean>(false);
  const [dataLoadingInstallation, setDataLoadingInstallation] = useState<boolean>(false);
  const [dataLoadingProject, setDataLoadingProject] = useState<boolean>(false);

  const [services, setServices] = useState<any>([]);
  const [weclappUsers, setWeclappUsers] = useState<any>([]);
  const [servicesRemote, setServicesRemote] = useState<any>([]);
  const [servicesWorkshop, setServicesWorkshop] = useState<any>([]);
  const [servicesInstallation, setServicesInstallation] = useState<any>([]);
  const [servicesProject, setServicesProject] = useState<any>([]);
  const [selectedService, setSelectedService] = useState<any>(user?.services.service ?? { id: '', name: '' });
  const [selectedWeclappUsers, setSelectedWeclappUsers] = useState<any>(user?.weclappUser ?? { id: '', name: '' });
  const [selectedRemote, setSelectedRemote] = useState<any>(user?.services.remote ?? { id: '', name: '' });
  const [selectedWorkshop, setSelectedWorkshop] = useState<any>(user?.services.workshop ?? { id: '', name: '' });
  const [selectedInstallation, setSelectedInstallation] = useState<any>(user?.services.installation ?? { id: '', name: '' });
  const [selectedProject, setSelectedProject] = useState<any>(user?.services.project ?? { id: '', name: '' });

  const [loading, setLoading] = useState<boolean>(false);
  const loadInstallationServices = () => {
    setDataLoadingInstallation(true);

    axios.get('/api/services/search?name=Neuinstallation').then((response) => {

      console.log('response', response, response.data);

      setServicesInstallation(response.data);

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setDataLoadingInstallation(false);
    });
  };
  const loadProjectServices = () => {
    setDataLoadingProject(true);

    axios.get('/api/services/search?name=Projekt').then((response) => {

      console.log('response', response, response.data);

      setServicesProject(response.data);

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setDataLoadingProject(false);
    });
  };

  const loadServices = () => {
	  setDataLoading(true);

	  axios.get('/api/services/search?name=Dienstleistung').then((response) => {

      	console.log('response', response, response.data);

      setServices(response.data);

    }).catch((error) => {
		  console.log(error	);
    }).then(() => {
		  setDataLoading(false);
    });
  };

  const loadWeclappUsers = () => {
    setDataLoadingUser(true);

    axios.get('/api/weclappuser/search').then((response) => {

      console.log('response', response, response.data);

      setWeclappUsers(response.data);

    }).catch((error) => {
      console.log(error	);
    }).then(() => {
      setDataLoadingUser(false);
    });
  };

  const loadRemoteServices = () => {
	  setDataLoadingRemote(true);

	  axios.get('/api/services/search?name=Fernwartung').then((response) => {

      	console.log('response', response, response.data);

      setServicesRemote(response.data);

    }).catch((error) => {
		  console.log(error	);
    }).then(() => {
		  setDataLoadingRemote(false);
    });
  };

  const loadWorkshopServices = () => {
	  setDataLoadingWorkshop(true);

	  axios.get('/api/services/search?name=Werkstatt').then((response) => {

      	console.log('response', response, response.data);

      setServicesWorkshop(response.data);

    }).catch((error) => {
		  console.log(error	);
    }).then(() => {
		  setDataLoadingWorkshop(false);
    });
  };

  useEffect(() =>  {
	  loadServices();
	  loadRemoteServices();
	  loadWorkshopServices();
	  loadProjectServices();
	  loadInstallationServices();
	  loadWeclappUsers();
  }, []);

  const handleUpdateUser = () => {

	  let data: any = {
		  id: user?.id,
		  firstname: firstname,
		  lastname: lastname,
		  email: email,
		  role: role,
		  weclappUser: {
			  id: selectedWeclappUsers.id?.toString(),
			  name: selectedWeclappUsers.name,
		  },
		  services: {
			  service: {
				  id: selectedService.id?.toString(),
				  name: selectedService.name,
			  },
			  remote: {
				  id: selectedRemote.id?.toString(),
				  name: selectedRemote.name,
			  },
			  workshop: {
				  id: selectedWorkshop.id?.toString(),
				  name: selectedWorkshop.name,
			  },
			  installation: {
				  id: selectedInstallation.id?.toString(),
				  name: selectedInstallation.name,
			  },
			  project: {
				  id: selectedProject.id?.toString(),
				  name: selectedProject.name,
			  },
		  },
		  created_at: user?.created_at,
		  updated_at: user?.updated_at,
	  };

	  if (password !== '' && password.length > 0) {
		  data.password = password;
	  }

	  setLoading(true);

	  const id = data.id ?? '';

	  axios.post(`/api/admin/users/${id}`, data).then((response) => {

		  console.log('response', response, response.data);

		  if (modalMode === 'add') {
			  data = response.data.data;
		  }

		  onClick(data);

	  }).catch((error) => {
		  console.log(error	);
	  }).then(() => {
		  setLoading(false);
	  });

  };

  const changeRole = (event: any) => {

	  console.log('val', event.target.value);
	  setRole(event.target.value);

	  let data = roles;
	  data.map((el) => el.selected = false);
	  data[data.findIndex((el) => el.value === event.target.value)].selected = true;
	  setRoles(data);

  };

  return (
	<Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" open={open} onClose={onClose}>
		<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

			<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

			{/* This element is to trick the browser into centering the modal contents. */}
			<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
				&#8203;
			</span>
			<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
				<div className="mt-10 sm:mt-0">
					<div className="">
						<div className="">
							<div className="px-4 sm:px-0">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Nutzer Informationen</h3>
								<p className="mt-1 text-sm text-gray-600">Verwalte den ausgewählten Nutzer und aktualisiere seine assoziierten Dienstleistungen.</p>
							</div>
						</div>
						<div className="mt-5">
							<form action="#" method="POST">
								<div className="shadow overflow-hidden sm:rounded-md">
									<div className="px-4 py-5 bg-white sm:p-6">
										<div className="grid grid-cols-2 gap-6">
											<div className="col-span-12">
												<Input label={'Vorname'} value={firstname} onChange={(event, name) => setFirstname(event.target.value)} name={'firstname'} />
											</div>

											<div className="col-span-12">
												<Input label={'Nachname'} value={lastname} onChange={(event, name) => setLastname(event.target.value)} name={'lastname'}/>
											</div>

											<div className="col-span-12">
												<Input label={'Email'} value={email} onChange={(event, name) => setEmail(event.target.value)} name={'email'}/>
											</div>

											<div className="col-span-12">
												<Input label={'Passwort'} value={password} onChange={(event, name) => setPassword(event.target.value)} name={'password'} type={'password'}/>
											</div>

											<div className="col-span-12">
												<Select label={'Rolle'} onSelect={(event) => changeRole(event)} data={roles} />
											</div>

											<div className="col-span-12">
												<SearchInput data={weclappUsers} selected={selectedWeclappUsers} onSelection={(item: any) => setSelectedWeclappUsers(item)} label="Weclapp Nutzer" loading={dataLoadingUser} />
											</div>

											<div className="col-span-12">
												<SearchInput data={services} selected={selectedService} onSelection={(item: any) => setSelectedService(item)} label="Dienstleistung" loading={dataLoading} />
											</div>

											<div className="col-span-12">
												<SearchInput data={servicesRemote} selected={selectedRemote} onSelection={(item: any) => setSelectedRemote(item)} label="Fernwartung" loading={dataLoadingRemote} />
											</div>

											<div className="col-span-12">
												<SearchInput data={servicesWorkshop} selected={selectedWorkshop} onSelection={(item: any) => setSelectedWorkshop(item)} label="Werkstatt" loading={dataLoadingWorkshop} />
											</div>

											<div className="col-span-12">
												<SearchInput data={servicesInstallation} selected={selectedInstallation} onSelection={(item: any) => setSelectedInstallation(item)} label="Neuinstallation" loading={dataLoadingInstallation} />
											</div>


											<div className="col-span-12">
												<SearchInput data={servicesProject} selected={selectedProject} onSelection={(item: any) => setSelectedProject(item)} label="Projekt" loading={dataLoadingProject} />
											</div>

										</div>
									</div>
									<div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
										<Button onClick={() => handleUpdateUser()} disabled={loading || dataLoading} loading={loading} label={modalMode == 'add' ? 'Erstellen' : 'Speichern'} />
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Dialog>
  );
};
