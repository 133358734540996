import React, { useCallback, useEffect, useState } from 'react';
import { Order } from '../../interfaces/Order';
import moment, { Moment } from 'moment';
import { useAuth } from '../../auth/AuthProvider';
import axios from 'axios';

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import set from 'lodash.set';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'lodash.clonedeep';

import { RadioOption } from '../../interfaces/RadioOption';
import { Input } from '../../components/Input';
import { RadioSelect } from '../../components/RadioSelect';
import { InputStaff } from '../../components/InputStaff';
import { Date } from '../../components/Date';
import { CalculateJourney, JourneyType } from '../../components/CalculateJourney';
import { Material } from '../../components/Material';
import { Button } from '../../components/Button';
import { Signature } from '../../components/Signature';
import { SuccessModal } from '../../components/modals/SuccessModal';
import { ErrorModal } from '../../components/modals/ErrorModal';
import { ActivityForm } from '../../components/ActivityForm';
import { Staff } from '../../interfaces/User';
import { Textarea } from '../../components/Textarea';
import { useSearchParams } from 'react-router-dom';


interface Props {
}

const activityOptions: RadioOption[] = [
  {
    name: 'Vor Ort',
    value: 'on_site',
  },
  {
    name: 'Fernwartung',
    value: 'remote',
  },
  {
    name: 'Werkstatt',
    value: 'workshop',
  },
  {
    name: 'Neuinstallation',
    value: 'installation',
  },
  {
    name: 'Projektarbeit',
    value: 'project',
  },
];

const nextAppointmentOptions = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];


const notDoneOptions = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];


const driveOptions: RadioOption[] = [
  {
    name: 'Ja',
    value: 'yes',
  },
  {
    name: 'Nein',
    value: 'no',
  },
];

const initialOrder: Order = {
  id: 0,
  orderNumber: '',
  serviceDate: null,
  activity: '',
  notDone: notDoneOptions[1],
  subject: '',
  company: {
    id: 0,
    number: 0,
    name: '',
    customerSalesChannel: '',
  },
  material: {
    ics: [],
    mvz: [],
  },
  staff: [],
  signatures: {
    wk: '',
    customer: '',
  },
  activityForm: activityOptions[0],
  nextAppointment: null,
  nextAppointmentAdditionalInfo: '',
  route: {
    id: 0,
    name: '',
    kilometer: '0',
    option: driveOptions[0],
  },
};


export const Mvz: React.FC<Props> = () => {

  const [order, setOrder] = useState<Order>(cloneDeep(initialOrder));
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('');

  const [actOptions, setActOptions] = useState<RadioOption[]>(activityOptions);
  const [nAOptions, setNAOptions] = useState<RadioOption[]>(nextAppointmentOptions);
  const [driOptions, setDriOptions] = useState<RadioOption[]>(driveOptions);
  const [queryParameter] = useSearchParams();

  const [ticketNotReady, setTicketNotReady] = useState<boolean>(false);
  const [ticketNotReadyComment, setTicketNotReadyComment] = useState<string>('Ticket nicht bearbeitet');


  useEffect(() => {
    console.log('TEST', queryParameter);
  }, [queryParameter]);

  const auth = useAuth();

  useEffect(() => {

    console.log('clone initial order');

    let tmpOrder = cloneDeep(initialOrder);

    tmpOrder.activityForm = actOptions[0];
    tmpOrder.nextAppointment = null;
    tmpOrder.route.option = driOptions[0];

    setOrder(tmpOrder);

  }, []);

  const fetchOrder = (event: any) => {
    console.log('fetch order', event.target.value, order);
    let ticketName = event.target.value;
    if (!ticketName.startsWith('AB')) {
      ticketName = 'AB-W' + ticketName;
    }
    axios.get(`/api/order/${ticketName}`).then((response) => {

      console.log('response', response, response.data);

      const data = response.data;
      setOrder({ ...order, ...data });

    }).catch((error) => {
      console.log(error);
    }).then(() => {
      setOrderLoading(false);
    });
  };

  useEffect(() => {
    console.log(order);
  }, [order]);

  const debouncedChangeHandler = useCallback(debounce(fetchOrder, 300), [order]);

  const handleChangeOrderNumber = (event: any) => {
    setOrderLoading(true);
    setOrder((prev) => ({ ...prev, orderNumber: event.target.value }));
    debouncedChangeHandler(event);
  };

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const handleValueChanged = (event: any, stateName: any) => {
    const val = event.target.value;

    // @See https://stackoverflow.com/questions/6393943/convert-a-javascript-string-in-dot-notation-into-an-object-reference
    setOrder((prev: Order) => {
      set(prev, stateName, val);
      return { ...prev };
    });
  };

  const onRadioChangeActivity = (value: any) => {
    console.log('on readio change', value);
    setOrder((prev) => ({ ...prev, activityForm: value }));
  };

  const onRadioChangeNextAppointment = (value: any) => {
    console.log('on readio change', value);
    setOrder((prev) => ({ ...prev, nextAppointment: value }));
  };

  const onRadioChangeNotDone = (value: any) => {
    console.log('on readio change', value, value === notDoneOptions[0]);
    if (value === notDoneOptions[1]) setTicketNotReady(true);
    if (value === notDoneOptions[0]) setTicketNotReady(false);

  };

  const onJourneyChange = (value: RadioOption) => {
    setOrder((prev) => ({ ...prev, calculateJourney: value }));
  };

  const staffChanged = (item: any) => {
    if (order.staff.findIndex((staff) => staff.id === item.id) > -1) return;

    order.staff.push(item);
    setOrder({ ...order });
  };

  const removeStaff = (userId: any) => {
    console.log('remove staff', userId, order.staff.findIndex((el) => el.id === userId));
    order.staff.splice(order.staff.findIndex((el) => el.id === userId), 1);
    setOrder({ ...order });
  };

  const hoursChanged = (user: Staff) => {
    const index = order.staff.findIndex((el) => el.id === user.id);
    order.staff[index] = user;
    setOrder({ ...order });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    console.log(order);
    setSubmitting(true);
    if (ticketNotReady) {
      axios.post('/api/protocols/mvz/not-ready', {
        id: order.id,
        comment: ticketNotReadyComment,
      })
        .then(response => {
          setStatus('success');
          console.log(response);

          const tmpOrder = cloneDeep(initialOrder);

          tmpOrder.activityForm = actOptions[0];
          tmpOrder.nextAppointment = null;
          tmpOrder.serviceDate = null;
          tmpOrder.route.option = driOptions[0];

          console.log('tmpOrder', tmpOrder, initialOrder);

          setOrder({ ...tmpOrder });
          setTicketNotReady(false);
        }).catch(error => {
          setStatus('error');
          console.log(error);
        }).then(() => {
          setShowModal(true);
          setSubmitting(false);
        });
      return false;
    }


    axios.post('/api/protocols/mvz', order)
      .then(response => {
        setStatus('success');
        console.log(response);

        const tmpOrder = cloneDeep(initialOrder);

        tmpOrder.activityForm = actOptions[0];
        tmpOrder.nextAppointment = null;
        tmpOrder.serviceDate = null;
        tmpOrder.route.option = driOptions[0];

        console.log('tmpOrder', tmpOrder, initialOrder);

        setOrder({ ...tmpOrder });

      }).catch(error => {
        setStatus('error');
        console.log(error);
      }).then(() => {
        setShowModal(true);
        setSubmitting(false);
      });

    return false;
  };

  const setMvzMaterial = (materials: any[]) => {
    console.log('setMvzMaterial', materials);
    order.material.mvz = materials;
    setOrder({ ...order });
  };

  const setAdditionalInfoText = (info: string) => {
    order.nextAppointmentAdditionalInfo = info;
    setOrder({ ...order });
  };

  const setWkSignature = (data: any) => {
    order.signatures.wk = data;
    setOrder({ ...order });
  };

  const setCustomerSignature = (data: any) => {
    order.signatures.customer = data;
    setOrder({ ...order });
  };

  const validateDate = (date: Moment): boolean => {
    if (date.isAfter(moment())) {
      setDateErrorMessage('Das angegbene Datum liegt in der Zukunft');
      return false;
    }
    setDateErrorMessage('');
    return true;
  };

  const handleDateChange = (date: any) => {
    setOrder((prev) => ({ ...prev, serviceDate: date }));
    validateDate(date);
  };

  const setKilometer = (kilometer: string) => {
    order.route.kilometer = kilometer;
    setOrder({ ...order });
  };

  const setRoute = (id: number, name: string) => {
    order.route.id = id;
    order.route.name = name;
    setOrder({ ...order });
  };


  const setDriveOption = (item: RadioOption) => {
    order.route.option = item;
    if (item.value == 'no') {
      order.route.id = 0;
      order.route.name = '';
      order.route.kilometer = '0';
    }
    setOrder({ ...order });
  };

  const handleClose = () => {
    setStatus('');
    setShowModal(false);
  };

  return (
	<div className="bg-wk">

		<form action="" onSubmit={handleSubmit}>
			<div className="isolate -space-y-px shadow-sm">
				<div className="flex justify-center mb-3">
					<img src={process.env.REACT_APP_BASE_URL + '/mvz_logo.png'} alt=""
                             className="h-12 w-auto object-contain"/>
				</div>
				{order.status === 'CLOSED' && <p style={{ color: 'red' }}>Auftrag bereits abgeschlossen</p>}
				<Date errorMessage={dateErrorMessage} label={'Leistungsdatum: *'} name={'serviceDate'}
                          onChange={handleDateChange} value={order.serviceDate} required={true}/>
				<Input label={'Weclapp Auftragsnummer: *'} name={'order_number'} required={true}
                           onChange={handleChangeOrderNumber} value={order.orderNumber} loading={orderLoading}
                           inlineStyling={false}/>
				<Input label={'Kunde: *'} name={'company.name'} required={true} value={order.company.name}
                           onChange={handleValueChanged} inlineStyling={false}/>
				<Input label={'Thema:'} name={'company.name'} required={false} value={order.subject} readonly={true}
                           inlineStyling={false}/>
				<RadioSelect
                        info={ticketNotReady ? 'ACHTUNG: Ticket status wird auf nicht bearbeitet geändert!!' : ''}
                        header={'Ticket bearbeitet?'} options={notDoneOptions} onChange={onRadioChangeNotDone}
                        selectedValue={!ticketNotReady ? notDoneOptions[0] : notDoneOptions[1]}/>
				{
                        !ticketNotReady ? <>

	<InputStaff label={'Mitarbeiter Webkultur: *'} name={'wk_staff'} staffList={order.staff}
                                        onChange={staffChanged} removeStaff={removeStaff} hoursChanged={hoursChanged}/>
	<Textarea autoComplete label={'Tätigkeit: *'} name={'activity'} required={true} value={order.activity}
                                      onChange={handleValueChanged}/>
	<Material label={'Material von Webkultur:'} value={'mvz'}
                                      selectedMaterials={order.material.mvz}
                                      setSelectedMaterials={(data) => setMvzMaterial(data)}/>
	<ActivityForm header={'Tätigkeitsform: *'} options={actOptions}
                                          onChange={onRadioChangeActivity} selectedValue={order.activityForm}
                                          includeWrapperClassnames={false} staff={order.staff}/>
	<CalculateJourney name={'journey'}
                                              route={order.route}
                                              setKilometer={setKilometer}
                                              setRoute={setRoute}
                                              selectedRoute={order.route}
                                              options={driOptions}
                                              disabled={order.activityForm.value === 'workshop' || order.activityForm.value === 'remote'}
                                              type={((order.activityForm.value === 'on_site') && (order.company.customerSalesChannel === 'NET3' || order.company.customerSalesChannel === 'NET7')) ? JourneyType.WKP : JourneyType.WKS}
                                              setDriveOption={setDriveOption}/>
	<RadioSelect onAdditionalTextChange={setAdditionalInfoText}
                                         additionalInputEnabled={order.nextAppointment?.value === 'yes'}
                                         info={order.nextAppointment?.value === 'no' ? 'ACHTUNG: Ticket wird geschlossen' : ''}
                                         header={'Folgetermin nötig: *'} options={nAOptions}
                                         onChange={onRadioChangeNextAppointment} selectedValue={order.nextAppointment}/>
	<Signature label={'Unterschrift Webkultur'} onSave={setWkSignature}
                                       data={order.signatures.wk}/>
	<Signature label={'Unterschrift Kunde'} onSave={setCustomerSignature}
                                       data={order.signatures.customer}/>

                        </> : <>
	<Input label={'Kommentar: *'} name={'comment'} required={true}
                                   onChange={(event) => {setTicketNotReadyComment(event.target.value);}} value={ticketNotReadyComment} loading={orderLoading}
                                   inlineStyling={false}/>

                        </>}
				<Button type={'submit'} label={'Speichern'}
                        disabled={submitting || order.status === 'CLOSED' || dateErrorMessage !== ''}
                        loading={submitting} classNames="w-full mt-4"/>

			</div>
		</form>
		{status == 'success' &&
		<SuccessModal open={showModal} onClose={handleClose}/>
            }
		{status == 'error' &&
		<ErrorModal open={showModal} onClose={handleClose}/>
            }

	</div>
  );
};
