import React, { useEffect, useState } from 'react';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log('we are being triggered :D');
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    window.addEventListener('appinstalled', (event) => {
      console.log('👍', 'appinstalled', event);
      // Clear the deferredPrompt so it can be garbage collected
      // @ts-ignore
      window.deferredPrompt = null;
    });

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
	<button
        className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-orange-900 bg-orange-400 hover:bg-orange-300 focus:outline-none
           flex flex-col gap-2 items-center"
          id="setup_button"
          aria-label="Install app"
          title="Install app"
          onClick={onClick}
      >
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
		</svg>
		<span>Als APP installieren</span>
	</button>
  );
};

export default InstallPWA;
