import React from 'react';
import './App.css';
import { Header } from './layout/Header';
import { Main } from './layout/Main';

function App() {
  return (
	<div className="App">
		<React.StrictMode>

			<Header />

			<Main />
		</React.StrictMode>

	</div>
  );
}

export default App;
