import React from 'react';
import Select from 'react-select';
import { Material } from '../interfaces/Material';

interface Props {
  data: any[],
  searchProperty: string,
  onSelect: (element: any) => void
}

export const Autocomplete: React.FC<Props> = ({ data = [], onSelect }: Props) => {

  const onClick = (suggestion: any) => {

    onSelect(suggestion);

  };

  return (
	<div>
		{data.length > 0 &&
		<Select options={data}
                onChange={onClick}
                getOptionLabel={(option: Material) => `${option.name}`}
                isSearchable
                isClearable
                value={null}
                placeholder={'Suchen...'}
        />
        }
	</div>
  );
};
