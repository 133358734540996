import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import InstallPwa from '../components/InstallPwa';
import { UpdateWaiting } from '../components/UpdateWaiting';
import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener';
import { UserStatistics } from '../components/UserStatistics';

interface Props {}

export const Home: React.FC<Props> = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState<any>(null);
  const [swListener, setSwListener] = useState({});

  useEffect( () => {

    if (process.env.NODE_ENV !== 'development') {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      // @ts-ignore
      listener.onupdateinstalling = (installingEvent: any) => {
        console.log('SW installed', installingEvent);
      };
      // @ts-ignore
      listener.onupdatewaiting = (waitingEvent) => {
        console.log('new update waiting', waitingEvent);
        setUpdateWaiting(true);
      };
      // @ts-ignore
      listener.onupdateready = (event) => {
        console.log('updateready event');
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        console.log('registration', reg);
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      /*return () => {
        if (listener)
        // @ts-ignore
          listener.removeEventListener();
      };*/
    } else {
      //do nothing because no sw in development
    }
  }, []);


  const handleNavigateClick = (url: string) => {
    navigate(url);
  };


  const handleUpdate = () => {
    // @ts-ignore
    swListener.skipWaiting(registration.waiting);
  };

  return (
	<div>

		<div className="flex flex-col gap-10 lg:px-20 lg:mt-10 select-none">

			<UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate}/>
			<UserStatistics/>

			<button
				type="button"
				className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-wk-600 hover:bg-wk-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    flex flex-col gap-2 items-center"
				onClick={() => handleNavigateClick('/protocols/mvz')}
			>
				<img src={process.env.REACT_APP_BASE_URL + '/mvz_logo.png'} alt="" className="h-28 w-auto object-contain"/>
				<span>Neuer Lieferschein</span>
			</button>
			{/*<button*/}
			{/*    type="button"*/}
			{/*    className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-wk-400*/}
			{/*        flex flex-col items-center"*/}
			{/*    onClick={() => handleNavigateClick('/protocols/ics')}*/}
			{/*>*/}
			{/*	<img src={process.env.REACT_APP_BASE_URL + '/ics_logo.png'} alt="" className="h-28 w-auto object-contain"/>*/}
			{/*	<span>ICS</span>*/}
			{/*</button>*/}

			{auth.user.role === 'admin' &&
			<button
                    type="button"
                    className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    flex flex-col gap-2 items-center"
                    onClick={() => handleNavigateClick('/users')}
                >
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
				</svg>
				<span>Nutzerverwaltung</span>
			</button>
            }

			{auth.user.role === 'admin' &&
			<button
                    type="button"
                    className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    flex flex-col gap-2 items-center"
                    onClick={() => handleNavigateClick('/statistics')}
                >
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
				</svg>
				<span>Stundenauswertung</span>
			</button>
            }

			<button
                type="button"
                className="px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-800 hover:bg-yellow-900 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-wk-400
                    flex flex-col items-center"
                onClick={() => handleNavigateClick('/protocols')}
            >
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
				</svg>
				<span>Protokollarchiv</span>
			</button>

			<InstallPwa />

			<div className="text-sm text-gray-500 text-center">
				v{process.env.REACT_APP_VERSION}
			</div>

		</div>
	</div>
  );
};
