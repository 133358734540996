import React from 'react';
import { Dialog } from '@headlessui/react';

interface Props {
  open: boolean,
  onClose: () => void
}

export const ErrorModal: React.FC<Props> = ({ open, onClose }: Props) => {

  return (
	<Dialog as="div" className="relative z-10" onClose={onClose} open={open}>
		<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

		<div className="fixed z-10 inset-0 overflow-y-auto">
			<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

				<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
					<div className="sm:flex sm:items-start">
						<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
								Fehler
							</Dialog.Title>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									Fehler beim erstellen des Protokolls
								</p>
							</div>
						</div>
					</div>
					<div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
						<button type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
								onClick={() => onClose()}>
							Schließen
						</button>
					</div>
				</div>
			</div>
		</div>
	</Dialog>
  );
};


