import React from 'react';

interface Props {
  previousPage: () => void,
  nextPage: () => void,
  currentPage: number,
  totalPages: number
}

export const Pagination = ({ previousPage, nextPage, currentPage, totalPages } : Props) => {

  return (
	<nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
		<div className="-mt-px w-0 flex-1 flex">
			<a
              href="#"
			  onClick={previousPage}
              className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
				<svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
				</svg>
				Vorherige
			</a>
		</div>
		<div className="hidden md:-mt-px md:flex">
			<p className="text-sm text-gray-700 pt-4">
				Seite <span className="font-medium">{currentPage}</span> / <span className="font-medium">{totalPages}</span>
			</p>
		</div>
		<div className="-mt-px w-0 flex-1 flex justify-end">
			<a
              href="#"
			  onClick={nextPage}
              className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
				Nächste
				<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
				</svg>
			</a>
		</div>
	</nav>
  );
};
