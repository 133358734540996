import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useLocation } from 'react-router-dom';

export const ProtectedRoutes = ({ redirectPath = '/login' }: { redirectPath: string }) => {
  let auth = useAuth();
  const location = useLocation();


  if (!auth.user) {
    return <Navigate to={redirectPath} replace state={{ from: location }}/>;
  }

  return <Outlet/>;
};
